import React from 'react'

function Activities() {

  const activities = [
    {
      title: "FootBall",
      img: "/images/soccer.png"
    },
    {
      title: "basket",
      img: "/images/basket.png"
    },
    {
      title: "tennis",
      img: "/images/tennis.png"
    },
    {
      title: "surf",
      img: "/images/surf.png"
    },
    {
      title: "games",
      img: "/images/games.png"
    },
    {
      title: "break",
      img: "/images/break.png"
    },
    {
      title: "cinema",
      img: "/images/cinema.png"
    },
    {
      title: "music",
      img: "/images/music.png"
    },
    {
      title: "paint",
      img: "/images/paint.png"
    }
  ]


  return (
    <div className='min-h-screen flex items-center justify-center flex-wrap py-12 gap-x-28 sm:space-y-10 md:gap-x-60 md:px-24'>
      {
        activities.map( (activity) =>(
          <div   style={{ backgroundImage: `url(${activity.img})` }} className='w-40 h-32 md:w-80 md:h-60 bg-slate-800 content-center text-center text-xl md:text-3xl border-2 border-black bg-cover shadow-lg hover:border-none rounded-sm cursor-pointer'><p className='w-full bg-gray-400'>{activity.title}</p></div>
        )
        )

        
      }

    </div>
  )
}

export default Activities