import React from 'react'

function Failed() {

    const retry = () => {
        window.location.reload()
    }

  return (
    <div className='min-h-screen flex flex-col justify-center items-center '>
        <div className='flex flex-col justify-center items-center w-[400px] h-[600px] md:w-[600px] md:h-[300px] space-y-9 md:space-y-9 md:p-11'>
            <div className='flex flex-col md:flex-row justify-center items-center space-y-7 md:space-x-5'>
                <div>
                    <svg className='w-20 h-20' xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px" viewBox="0 -1 26 26"><path fill-opacity=".3" d="M24.24 8l1.35-1.68C25.1 5.96 20.26 2 13 2S.9 5.96.42 6.32l12.57 15.66.01.02.01-.01L20 13.28V8h4.24z"/><path d="M22 22h2v-2h-2v2zm0-12v8h2v-8h-2z"/></svg>
                </div>
                <div>
                    <p>Sorry, no internet connection detected.</p>
                    <p>Please check your connection and try again</p>
                </div>
            
            </div>
            <div className='md:self-end'>
                <button onClick={retry} className='bg-blue-500 text-white w-20 h-9 rounded'>Retry</button>
            </div>
        </div>
    </div>
  )
}

export default Failed