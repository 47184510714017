import React from 'react'
import '../styleLoading.css'

function Loading() {
  return (
    <div className='min-h-screen flex justify-center items-center gap-2'>
        <div role="status">
          <div class="container">
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
            <div class="dot"></div>
          </div>
        </div>
        <p className='font-serif text-3xl'>Loading...</p>
    </div>
  )
}

export default Loading