import React from 'react'

function Footer() {
  return (
    <footer className="h-40 w-full bg-black text-white">
        <p>&copy; 2024 Weston College</p>
    </footer>
  )
}

export default Footer