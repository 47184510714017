import { app } from '../firebaseConfig'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function SignIn() {

  const [placeholderEmail, setPlaceholderEmail] = useState('Email')
  const [placeholderPassword, setPlaceholderPassword] = useState('Password')

  const [email, setEmail] = useState('')
  const [pw, setPw] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const auth = getAuth()


  const SIGNIN = async (Event) => {
    Event.preventDefault()
    
    try{
      await signInWithEmailAndPassword(auth, email, pw)
      navigate('/')
    } catch (error) {
      setError(error.message)
    }
  
  }


  return (
    <div className='min-h-screen flex mt-36 items-center flex-col space-y-10 md:space-y-16'>
        <h1 className='cursor-default text-2xl md:text-4xl'>Good to see you again</h1>
        <form onSubmit={SIGNIN} className='flex flex-col justify-center items-center w-[350px] h-[410px] md:w-[500px] md:h-[350px] py-10 rounded-lg shadow-lg bg-slate-50 space-y-10 md:space-y-8 px-7 md:px-12'>
            <input type="email" value={email} onChange={ (e) => setEmail(e.target.value) } placeholder={placeholderEmail} onFocus={() => setPlaceholderEmail('')} onBlur={() => setPlaceholderEmail('Email')} className='w-full h-9 border border-gray-300 px-1 rounded-md focus:outline-none focus:shadow-lg focus:border-black' />
            <input type="password" value={pw} onChange={ (e) =>setPw(e.target.value) } placeholder={placeholderPassword} onFocus={() => setPlaceholderPassword('')} onBlur={() => setPlaceholderPassword('Password')} className='w-full h-9 border border-gray-300 px-1 rounded-md focus:outline-none focus:shadow-lg focus:border-black' />
            {error && <p className='text-red-500'>{error}</p>}
            <button type='submit' className='bg-green-600 hover:bg-green-700 w-36 h-8 md:w-60 md:h-10 rounded-full text-white font-bold'>Sign in</button>
            <div className='self-start space-y-2 md:space-x-24 flex flex-col md:flex-row '>
              <Link to='/signup' className=' text-blue-700 underline font-bold text-sm'>Don't have an account?</Link>
              <Link to='/forgotpw' className='text-blue-700 underline font-bold text-sm'>Forgot password?</Link>
            </div>
        </form>
    </div>
    
  )
}

export default SignIn