import React, { useState } from "react"
import { BrowserRouter as Router, Routes, Route, Link, Form } from "react-router-dom"
import Header from "./components/Header"
import Footer from "./components/Footer"
import Home from "./pages/Home"
import Classes from "./pages/Classes"
import Activities from "./pages/Activities"
import Contact from "./pages/Contact"
import SignIn from "./pages/SignIn"
import SignUp from "./pages/SignUp"
import ForgotPassword from "./pages/ForgotPassword"
import Profile from "./pages/Profile"
export const myContext = React.createContext(null)


function App() {


  const [imageUrl, setImageUrl] = useState('')




  return (
    <Router>
      <div className="min-h-screen">
        <myContext.Provider value={{imageUrl, setImageUrl}}>
          <Header/>
          <main>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/classes" element={<Classes />} />
              <Route path="/activities" element={<Activities />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/signup" element={<SignUp />} />
              <Route path="/forgotpw" element={ <ForgotPassword/> } />
              <Route path="/profile" element={ <Profile/> } />
            </Routes>
          </main>
          <Footer/>
        </myContext.Provider>
      </div>
    </Router>
  )
}

export default App