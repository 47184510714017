// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD3wc3uP40-Ww_RZvHyihDkT6Y_tUSeRYc",
  authDomain: "weston-d0183.firebaseapp.com",
  projectId: "weston-d0183",
  storageBucket: "weston-d0183.appspot.com",
  messagingSenderId: "1075433803986",
  appId: "1:1075433803986:web:a4e5b0bfddb4551b069d0b"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

const db = getFirestore(app)

const storage = getStorage(app)

export { app, auth, db, storage }
