import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../firebaseConfig'
import { onAuthStateChanged, signOut } from 'firebase/auth'
import Loading from './Loading'
import Failed from './Failed'
import { myContext } from '../App'

function Header() {
  const [user, setUser] = useState(null)
  const [menuVisible, setMenuVisible] = useState(false)
  const [loading, setLoading] = useState(true)
  const [failed, setFailed] = useState(false)
  const navigate = useNavigate()
  const {imageUrl, setImageUrl} = useContext(myContext)

  useEffect(() => {
    try{
      const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
        setUser(currentUser)
        setLoading(false)
      });
      return () => {
      unsubscribe()
      }
    }catch (error){
      setFailed(true)
    }

  }, [])

  const handleLogout = () => {
    signOut(auth)
    navigate('/')
  }




  if (loading) {
    return (
        <>
          <Loading/>
        </>
    )
  }

  if (failed) {
    return (
        <>
          <Failed/>
        </>
    )
  } 


  return (
    <>
      <div className='md:hidden bg-slate-100 flex justify-between items-center h-20 shadow-lg px-10 border-b hover:border-b-2 border-green-500'>
        <Link to='/'><h1 onClick={ () => {setMenuVisible(false)} } className='align-start text-3xl font-extrabold cursor-pointer'>Weston</h1></Link>
        <div className='cursor-pointer' onClick={() => {setMenuVisible(!menuVisible)}}>
          <svg className='w-10 h-10' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" width="64px" height="64px">
            <path d="M56 48c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 48 54.798 48 56 48zM56 32c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 32 54.798 32 56 32zM56 16c2.209 0 4 1.791 4 4 0 2.209-1.791 4-4 4-1.202 0-38.798 0-40 0-2.209 0-4-1.791-4-4 0-2.209 1.791-4 4-4C17.202 16 54.798 16 56 16z"/>
          </svg>
        </div>
        <div className='flex items-center space-x-5'>
          {user ? (
            <>
              <button onClick={() => { setMenuVisible(false); handleLogout(); }} className='bg-red-500 p-1 shadow rounded-2xl w-20 h-10 hover:bg-red-600'>Log Out</button>
              <Link to='/profile' className='cursor-pointer' onClick={ () => {setMenuVisible(false)} }>
                <div className='w-9 h-9 rounded-full bg-cover bg-center ' style={{ backgroundImage: `url(${imageUrl})` }}></div>
              </Link>
            </>
          ) : (
            <>
              <Link to='/signin'>
                <button onClick={ () => {setMenuVisible(false)} } className='bg-green-500 p-1 shadow rounded-2xl w-20 h-10 hover:bg-green-600'>Sign In</button>
              </Link>
              <Link to='/signup'>
                <button onClick={ () => {setMenuVisible(false)} } className='bg-green-500 p-1 shadow rounded-2xl w-20 h-10 hover:bg-green-600'>Sign Up</button>
              </Link>
            </>
          )}
        </div>
      </div>

      {menuVisible && (
        <div className='md:hidden absolute left-1/3 top-20 bg-slate-500 w-60 z-50'>
          <ul className='nav-list flex flex-col justify-center items-center'>
            <Link to='/' onClick={ () => {setMenuVisible(false)} } className='nav-item h-16 w-full border-b-2 flex hover:bg-slate-400'>
              <Link to='/' className='nav-link  text-xl self-center px-6'>Home</Link>
            </Link>
            <Link to='/classes' onClick={ () => {setMenuVisible(false)} } className='nav-item h-16 w-full border-b-2 flex hover:bg-slate-400'>
              <Link to='/classes' className='nav-link  text-xl self-center px-6'>Classes</Link>
            </Link>
            <Link to='/activities' onClick={ () => {setMenuVisible(false)} } className='nav-item h-16 w-full border-b-2 flex hover:bg-slate-400'>
              <Link to='/activities' className='nav-link  text-xl self-center px-6'>Activities</Link>
            </Link>
            <Link to='/contact' onClick={ () => {setMenuVisible(false)} } className='nav-item h-16 w-full border-b-2 flex hover:bg-slate-400'>
              <Link to='/contact' className='nav-link  text-xl self-center px-6'>Contact</Link>
            </Link>
          </ul>
        </div>
      )}

      <header className='hidden md:flex bg-slate-100 justify-between items-center h-20 shadow-lg px-10 border-b hover:border-b-2 border-green-500'>
        <Link to='/'><h1 onClick={ () => {setMenuVisible(false)} } className='align-start text-3xl font-extrabold cursor-pointer'>Weston</h1></Link>
        <ul className='nav-list flex justify-center items-center space-x-12'>
          <li className='nav-item'>
            <Link to='/' className='nav-link hover:text-gray-400 text-xl'>Home</Link>
          </li>
          <li className='nav-item'>
            <Link to='/classes' className='nav-link hover:text-gray-400 text-xl'>Classes</Link>
          </li>
          <li className='nav-item'>
            <Link to='/activities' className='nav-link hover:text-gray-400 text-xl'>Activities</Link>
          </li>
          <li className='nav-item'>
            <Link to='/contact' className='nav-link hover:text-gray-400 text-xl'>Contact</Link>
          </li>
        </ul>
        <div className='flex items-center space-x-5'>
          {user ? (
            <>
              <button onClick={ handleLogout} className='bg-red-500 p-1 shadow rounded-2xl w-20 h-10 hover:bg-red-600'>Log Out</button>
              <Link to='/profile' className='cursor-pointer' >
                <div className='w-9 h-9 rounded-full bg-cover bg-center' style={{ backgroundImage: `url(${imageUrl})` }}></div>
              </Link>
            </>
          ) : (
            <>
              <Link to='/signin'>
                <button onClick={ () => {setMenuVisible(false)} } className='bg-green-500 p-1 shadow rounded-2xl w-20 h-10 hover:bg-green-600'>Sign In</button>
              </Link>
              <Link to='/signup'>
                <button onClick={ () => {setMenuVisible(false)} } className='bg-green-500 p-1 shadow rounded-2xl w-20 h-10 hover:bg-green-600'>Sign Up</button>
              </Link>
            </>
          )}
        </div>
      </header>
    </>
  )
}

export default Header
