import React from 'react'



function Home() {
  return (
    <div className='border-none bg-custom-image bg-cover min-h-screen'>
        <div className="title flex items-center justify-center flex-col relative top-28 cursor-default">
            <h1 className='text-3xl sm:text-4xl lg:text-5xl'>Welcome to</h1>
            <p className='text-5xl sm:text-7xl md:text-8xl lg:text-9xl text-gray-950'>Weston College</p>
        </div>
    </div>
  )
}

export default Home