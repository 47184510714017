import { sendPasswordResetEmail } from 'firebase/auth'
import React, { useState } from 'react'
import { app } from '../firebaseConfig'
import { auth } from '../firebaseConfig'

function ForgotPassword() {
    const [emailPlaceholder, setEmailPlaceholder] = useState('Email')
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')
    const [message, setMessage] = useState('')


    const passwordReset = async (e) => {
        e.preventDefault()
        try{
            await sendPasswordResetEmail(auth, email)
            setMessage('Password reset email sent! Please check your inbox.')
        }catch (error){
            setError(error.message)
        }
    }




  return (
    <div className='min-h-screen flex mt-36 items-center flex-col space-y-16'>
        <h1 className="cursor-default text-2xl md:text-4xl">Forgot Password</h1>
        <div className="flex flex-col justify-center items-center w-[350px] h-[250px] md:w-[500px] md:h-[350px] rounded-lg shadow-lg bg-slate-50 space-y-8 px-12 py-10">
            
            <input type="email" value={email} onChange={ (e) => setEmail(e.target.value) } placeholder={emailPlaceholder} onFocus={ () => setEmailPlaceholder('') } onBlur={ () => setEmailPlaceholder('Email') } className='w-full h-9 border border-gray-300 px-1 rounded-md focus:outline-none focus:shadow-lg focus:border-black' />

            <button onClick={passwordReset} className="bg-green-600 hover:bg-green-700 w-36 h-8 md:w-60 md:h-10 rounded-full text-white font-bold">Reset Password</button>

            {error && <p className="text-red-500">{error}</p>}
            {message && <p className="text-green-500">{message}</p>}
            
        </div>
    </div>
  )
}

export default ForgotPassword

