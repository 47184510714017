import React, { useState } from 'react'

function Contact() {
    
    const [placeholderName, setPlaceholderName] = useState('Name')
    const [placeholderEmail, setPlaceholderEmail] = useState('Email')
    const [placeholderMessage, setPlaceholderMessage] = useState('Type message here')





  return (
      <div className='min-h-screen flex justify-center items-center '>
        <div className='w-96 md:w-[705px] flex items-center justify-center flex-col space-y-8 md:space-y-16 py-9 border-2 border-gray-300 shadow-xl rounded-md'>
            <h1 className='text-4xl md:text-7xl cursor-default'>Contact Us</h1>
            <div className='w-[700px] min-h-[400px] flex items-center justify-center flex-col space-y-12' >
                <input type="text" placeholder={placeholderName} onFocus={() => setPlaceholderName('')} onBlur={() => setPlaceholderName('Name')} className='w-72 h-9 md:w-[500px] md:h-[50px] border-b border-gray-300 focus:border-black focus:outline-none' />
                <input type="email" placeholder={placeholderEmail} onFocus={() => setPlaceholderEmail('')} onBlur={() => setPlaceholderEmail('Email')} className='w-72 h-9 md:w-[500px] md:h-[50px] border-b border-gray-300 focus:border-black focus:outline-none' />
                <div className='space-y-4'>
                    <p className='cursor-default'>Message:</p>
                    <textarea placeholder={placeholderMessage} on onFocus={() => setPlaceholderMessage('')} onBlur={() => setPlaceholderMessage('Type message here')} className='w-72 h-40 md:w-[500px] md:h-[200px] border border-gray-300 focus:outline-none focus:border-b-black focus:border-r-black'></textarea>
                </div>
                <button className='w-36 h-10 md:w-56 md:h-14 rounded-full border-2 hover:bg-neutral-400 bg-neutral-700 text-white hover:text-black text-lg md:text-xl shadow-xl '>Send</button>
            </div>
        </div>
    </div>
  )
}

export default Contact