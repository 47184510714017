import { app } from '../firebaseConfig'
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth'
import { getFirestore, doc, setDoc } from 'firebase/firestore'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

function SignUp() {

    const [placeholderFirstName, setPlaceholderFirstName] = useState('First Name')
    const [placeholderLastName, setPlaceholderLastName] = useState('Last Name')
    const [placeholderEmail, setPlaceholderEmail] = useState('Email')
    const [placeholderPassword, setPlaceholderPassword] = useState('Password')
    const [placeholderConfirm,  setPlaceholderConfirm] = useState('Confirm Password')

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const auth = getAuth()
    const db = getFirestore(app)

    const SIGNUP = async (Event) => {
      Event.preventDefault()

      if (firstName.trim() === ''){
        setError('Please provide your first name')
        return
      }

      if (lastName.trim() === ''){
        setError('Please provide your last name')
        return
      }

      if (email.trim() === ''){
        setError('Please enter your email')
        return
      }
      
      if (!/\S+@\S+\.\S+/.test(email)) {
        setError('Please enter a valid email address')
        return;
      }

      if (password.trim() === ''){
        setError('Please enter a password ')
        return
      }
      
      if (password.length < 6){
        setError('Password should be at least 6 characters')
      }
      
      if (confirmPassword.trim() === ''){
        setError('Please confirm your password')
        return
      }

      if (password !== confirmPassword){
        setError('Passwords do not match')
        return
      }


      setLoading(true)
      setError('')

      try {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password)
        const user = userCredential.user;

        // Store user name and email in Firestore
        await setDoc(doc(db, 'users', user.uid), {
          firstname: firstName,
          lastname: lastName,
          email: email
        })

        setLoading(false)
        navigate('/profile')

      } catch (error) {
        setLoading(false)
        setError(error.message)
      }
    }

  return (
    <div className='min-h-screen flex flex-col items-center space-y-10 md:space-y-16 mt-36'>
        <h1 className='cursor-default text-2xl md:text-4xl'>Hello friend!</h1>
        <form onSubmit={SIGNUP} className='w-[350px]  md:w-[500px] md:h-[600px] py-10 shadow-lg rounded-lg flex flex-col justify-center items-center bg-slate-50 space-y-10 md:space-y-8 px-12'>
            <input type="text" value={firstName} onChange={ (e) => setFirstName(e.target.value) } placeholder={placeholderFirstName} onFocus={() => setPlaceholderFirstName('')} onBlur={() => setPlaceholderFirstName('First Name')} className='w-full h-9 border border-gray-300 px-1 rounded-md focus:outline-none focus:shadow-lg focus:border-black' />
            <input type="text" value={lastName} onChange={ (e) => setLastName(e.target.value) } placeholder={placeholderLastName} onFocus={() => setPlaceholderLastName('')} onBlur={() => setPlaceholderLastName('Last Name')} className='w-full h-9 border border-gray-300 px-1 rounded-md focus:outline-none focus:shadow-lg focus:border-black' />
            <input  value={email} onChange={ (e) => setEmail(e.target.value) } placeholder={placeholderEmail} onFocus={() => setPlaceholderEmail('')} onBlur={() => setPlaceholderEmail('Email')} className='w-full h-9 border border-gray-300 px-1 rounded-md focus:outline-none focus:shadow-lg focus:border-black' />
            <input type="password" value={password} onChange={ (e) => setPassword(e.target.value) } placeholder={placeholderPassword} onFocus={() => setPlaceholderPassword('')} onBlur={() => setPlaceholderPassword('Password')} className='w-full h-9 border border-gray-300 px-1 rounded-md focus:outline-none focus:shadow-lg focus:border-black' />
            <input type="password" value={confirmPassword} onChange={ (e) => setConfirmPassword(e.target.value) } placeholder={placeholderConfirm} onFocus={() => setPlaceholderConfirm('')} onBlur={() => setPlaceholderConfirm('Confirm Password')} className='w-full h-9 border border-gray-300 px-1 rounded-md focus:outline-none focus:shadow-lg focus:border-black' />
            {error && <p className='text-red-500'>{error}</p>}
            <button type='submit' className='bg-green-600 hover:bg-green-700 w-36 h-8 md:w-60 md:h-10 rounded-full text-white font-bold' disabled={loading}>
              {loading ? 'Signing up...' : 'Sign up'}
            </button>
            <Link to='/signin' className='text-blue-700 underline font-bold text-sm'>Already have an account?</Link>
        </form>
    </div>
  )
}

export default SignUp
