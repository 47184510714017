import React, { useContext, useEffect, useState } from 'react'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { db, storage } from '../firebaseConfig'
import { useNavigate } from 'react-router-dom'
import Loading from '../components/Loading'
import Failed from '../components/Failed'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'
import { myContext } from '../App'

function Profile() {
    const auth = getAuth();
    const navigate = useNavigate()
    const [userData, setUserData] = useState({ firstname: '', lastname: '', email: '', profileImageUrl: '' })
    const [loading, setLoading] = useState(true)
    const [failed, setFailed] = useState(false)
    const [user, setUser] = useState(null)
    const [editMode, setEditMode] = useState(false)
    const [updatePicMode, setUpdatePicMode] = useState(false)
    const [image, setImage] = useState(null)
    const {imageUrl, setImageUrl} = useContext(myContext)
    const [error, setError] = useState('')


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            if (currentUser) {
                setUser(currentUser)
                fetchUserData(currentUser)
            } else {
                navigate('/signin')
            }
        })

        return () => unsubscribe()
    }, [auth, navigate])

    

    const fetchUserData = async (currentUser) => {
        try {
            const userDoc = await getDoc(doc(db, 'users', currentUser.uid))
            if (userDoc.exists()) {
                setUserData(userDoc.data())
                setImageUrl(userDoc.data().profileImageUrl)
                setLoading(false)
            } else {
                console.error('No such document!')
                setLoading(false)
                setFailed(true)
            }
        } catch (error) {
            console.error('Error fetching user data:', error)
            setLoading(false)
            setFailed(true)
        }
    }



    const btnSave = async () => {
        try {
            setLoading(true)
            await setDoc(doc(db, 'users', user.uid), userData)
            if (image) {
                const imgRef = ref(storage, `images/${user.uid}`)
                await uploadBytes(imgRef, image)
                const url = await getDownloadURL(imgRef)
                setUserData(prevData => ({ ...prevData, profileImageUrl: url }))
                setImageUrl(url)
            }
            setEditMode(false)
            setLoading(false)
        } catch (error) {
            console.error('Error updating user data:', error)
            setFailed(true)
        }
    }



    const upload = async () => {
        setUpdatePicMode(false)
        try {
            if (image) {
                const maxSizeInBytes = 1 * 1024 * 1024
                if (image.size > maxSizeInBytes){
                    setError('The image size exceeds 1MB. Please choose a smaller image.')
                    setUpdatePicMode(true)
                    return
                }else{
                    const imgRef = ref(storage, `images/${user.uid}`)
                    await uploadBytes(imgRef, image)
                    const url = await getDownloadURL(imgRef)
                    setUserData(prevData => ({ ...prevData, profileImageUrl: url }))
                    setImageUrl(url)
                }
            }
        } catch (error) {
            console.error('Error uploading image:', error)
            setFailed(true)
        }
    }

    const cancel = () => {
        setUpdatePicMode(false)
        setError('')
        setImage(null)
    }

    if (loading) {
        return <Loading />
    }

    if (failed) {
        return <Failed />
    }

    return (
        <div className='min-h-screen'>
            {updatePicMode && (
                <div className='min-h-screen min-w-full flex items-center justify-center z-30 absolute bg-gray-300 opacity-80'>
                    <div className='flex flex-col justify-center items-center gap-6 min-h-[200px] w-[550px] bg-white shadow-2xl py-14 px-12 z-50'>
                        <p className='text-3xl'>Change your picture</p>
                        <input 
                            type="file" 
                            accept='image/*' 
                            className='w-52 h-12' 
                            onChange={(e) => setImage(e.target.files[0])} 
                        />
                        {error && <p className='text-red-500'>{error}</p>}

                        <div className='flex self-end gap-8'>
                            <button 
                                className='bg-red-600 h-10 w-20 rounded-lg' 
                                onClick={cancel}
                            >
                                Cancel
                            </button>
                            <button 
                                className='bg-green-600 h-10 w-40 rounded-lg' 
                                onClick={upload}
                            >
                                Upload
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className='flex flex-col md:flex-row items-center space-y-10 md:space-x-10'>
                <div className='bg-cover h-60 w-60 border-2 rounded-full shadow mt-16 md:ms-16' style={{ backgroundImage: `url(${imageUrl})` }}>
                    {editMode && (
                        <>
                            <div className='w-60 h-60 rounded-full bg-gray-400 opacity-40 absolute top-36 cursor-pointer'></div>
                            <div onClick={() => {setUpdatePicMode(true)}} className='w-60 h-60 rounded-full flex flex-col justify-center items-center absolute top-36 cursor-pointer'>
                                <svg className='h-28 w-28 cursor-pointer' width="800px" height="800px" viewBox="0 0 24 24" id="_24x24_On_Light_Edit" data-name="24x24/On Light/Edit" xmlns="http://www.w3.org/2000/svg">
                                    <rect id="view-box" width="24" height="24" fill="none"/>
                                    <path id="Shape" d="M.75,17.5A.751.751,0,0,1,0,16.75V12.569a.755.755,0,0,1,.22-.53L11.461.8a2.72,2.72,0,0,1,3.848,0L16.7,2.191a2.72,2.72,0,0,1,0,3.848L5.462,17.28a.747.747,0,0,1-.531.22ZM1.5,12.879V16h3.12l7.91-7.91L9.41,4.97ZM13.591,7.03l2.051-2.051a1.223,1.223,0,0,0,0-1.727L14.249,1.858a1.222,1.222,0,0,0-1.727,0L10.47,3.91Z" transform="translate(3.25 3.25)" fill="#141124"/>
                                </svg>
                                <p className='text-2xl text-black cursor-pointer'>Edit image</p>
                            </div>
                        </>
                    )}
                </div>
                <div className='flex flex-col items-center justify-center space-y-1'>
                    <div className='flex flex-row justify-center items-center space-x-1'>
                        {editMode ? (
                            <>
                                <input 
                                    type="text" 
                                    className='h-12 w-60 outline-none text-3xl cursor-default' 
                                    value={userData.firstname} 
                                    onChange={(e) => setUserData({...userData, firstname: e.target.value})} 
                                />
                                <input 
                                    type="text" 
                                    className='h-12 w-60 outline-none text-3xl cursor-default' 
                                    value={userData.lastname} 
                                    onChange={(e) => setUserData({...userData, lastname: e.target.value})} 
                                />
                            </>
                        ) : (
                            <h1 className='text-3xl cursor-default'>{(userData.firstname + ' ' + userData.lastname).toUpperCase()}</h1>
                        )}
                    </div>
                    <div className='flex flex-row justify-center items-center space-x-1'>
                        <p className='text-gray-600 cursor-default'>{userData.email}</p>
                    </div>
                </div>
                <div>

                    {editMode ? (
                        <button onClick={btnSave} className='w-28 h-11 border-2 bg-zinc-700 text-white text-xl rounded-xl'>Save</button>

                    ):(
                        <button onClick={() => {setEditMode(true)}} className='w-28 h-11 border-2 bg-zinc-700 text-white text-xl rounded-xl'>Edit</button>
                    )}
                </div>
            </div>
        </div>
    )
}

export default Profile
