import React from 'react'
import { useRef } from 'react'


function Classes() {

  const devs = ['Software Development','Web Development','Backend Development','Video game Development','Mobile Development','Cloud Computing','Data Science']
  const designs = ['User Interface Design','3D graphic Design','Infographic','Illustration','Animation','Video game Design','Advertising Design']
  const sciences = ['Mathematics','Physics','Chemistry','Ecology','Astronomy','Social Science','Psychology']


    const detailsRef = useRef(null)

    const handleMouseEnter = () => {
        detailsRef.current.style.display = 'block'
    }

    const handleMouseLeave = () => {
        detailsRef.current.style.display = 'none'
    }



    const detailsRefGd = useRef(null)

    const handleMouseEnterGd = () => {
        detailsRefGd.current.style.display = 'block'
    }

    const handleMouseLeaveGd = () => {
        detailsRefGd.current.style.display = 'none'
    }


    const detailsRefScience = useRef(null)

    const handleMouseEnterScience = () => {
        detailsRefScience.current.style.display = 'block'
    }

    const handleMouseLeaveScience = () => {
        detailsRefScience.current.style.display = 'none'
    }





  return (
    <div className='Classes min-h-screen mt-36 md:mt-20 flex-col items-center xl:items-start xl:flex-row flex xl:justify-center  xl:space-x-16 space-y-6 '>

      <div onMouseLeave={handleMouseLeave}>
        <div className='h-36 w-72 md:h-48 md:w-96 mt-6 text-white text-3xl text-center content-center rounded-md bg-blue-900 cursor-pointer' onMouseEnter={handleMouseEnter} >Computer Development</div>
        <div className='hidden' ref={detailsRef} >
          {
            devs.map( (dev) => (
              <div className='h-14 w-72 md:h-20 md:w-96 border border-gray-500 bg-slate-100 content-center px-8 hover:bg-slate-500 text-xl'>{dev}</div>
            ))
          }
        </div>
      </div>
      

      <div onMouseLeave={handleMouseLeaveGd}>
        <div className='h-36 w-72 md:h-48 md:w-96 text-white text-3xl text-center content-center rounded-md bg-blue-900 cursor-pointer ' onMouseEnter={handleMouseEnterGd}>Graphic Design</div>
        <div className='hidden' ref={detailsRefGd}>
          {
            designs.map( (design) => (
              <div className='h-14 w-72 md:h-20 md:w-96 border border-gray-500 bg-slate-100 content-center px-8 hover:bg-slate-500 text-xl'>{design}</div>
            ))
          }
        </div>
      </div>


      <div onMouseLeave={handleMouseLeaveScience}>
        <div className='h-36 w-72 md:h-48 md:w-96 text-white text-3xl text-center content-center rounded-md bg-blue-900 cursor-pointer ' onMouseEnter={handleMouseEnterScience}>Science</div>
        <div className='hidden' ref={detailsRefScience}>
          {
            sciences.map( (science) =>(
              <div className='h-14 w-72 md:h-20 md:w-96 border border-gray-500 bg-slate-100 content-center px-8 hover:bg-slate-500 text-xl'>{science}</div>
            ))
          }
        </div>
      </div>

    </div>
  )
}

export default Classes